"use client";

import React, { createContext, useContext, useMemo } from "react";
import { ApiRoutes, ApiRoutesCompany, NextApiRoutes } from "@/services/routes";
import {
  AppCompanyRoutes,
  AppMenuRoutes,
  AppRoutes,
} from "@/config/app/routes";
import { Route } from "@ant-design/pro-layout/lib/typing";
import axios, { AxiosInstance } from "axios";
import { useEnvironment } from "@/providers/app/EnvironmentProvider";
import { useParams } from "next/navigation";
import {
  AuthMethod,
  useAuthentication,
} from "@/providers/app/AuthenticationProvider";
import { useTranslations } from "next-intl";
import { LOCAL_STORAGE_KEYS } from "@/utils/app/constants";
import { useLocalStorage } from "usehooks-ts";

interface GlobalAppStateProviderProps {
  children: React.ReactNode;
}

interface GlobalAppState {
  API_ROUTES: typeof ApiRoutes;
  API_ROUTES_COMPANY: ReturnType<typeof ApiRoutesCompany>;
  NEXT_API_ROUTES_COMPANY: ReturnType<typeof NextApiRoutes>;
  APP_ROUTES: typeof AppRoutes;
  APP_COMPANY_ROUTES: ReturnType<typeof AppCompanyRoutes>;
  MENU_ROUTES: Route;
  COMPANY_ID: string | undefined;
  axiosInstance: AxiosInstance;
  axiosInstanceNextAPI: AxiosInstance;
}

const GlobalAppStateContext = createContext<GlobalAppState | undefined>(
  undefined
);

export const GlobalAppStateProvider = ({
  children,
}: GlobalAppStateProviderProps) => {
  const t = useTranslations();
  const { method, API_KEY, ACCESS_COOKIE } = useAuthentication();
  const { API_URL, NEXT_API_URL } = useEnvironment();
  const params = useParams();
  const [redirectUrl] = useLocalStorage(
    LOCAL_STORAGE_KEYS.REDIRECT_URL,
    undefined,
    {
      initializeWithValue: false,
    }
  );

  const COMPANY_ID = useMemo(() => {
    if (params?.COMPANY_ID) {
      return params?.COMPANY_ID as string;
    }
    if (redirectUrl && redirectUrl !== "") {
      return redirectUrl?.match(/dashboard\/([^\/]+)\/settings/)?.[1];
    }
  }, [params, redirectUrl]);

  const axiosInstance = useMemo(() => {
    if (method === AuthMethod.credentials) {
      return axios.create({
        baseURL: API_URL,
        headers: {
          Authorization: `token ${ACCESS_COOKIE}`,
        },
      });
    }
    if (method === AuthMethod.apiKey) {
      return axios.create({
        baseURL: API_URL,
        headers: {
          "x-api-key": API_KEY,
        },
      });
    }
    return axios.create({
      baseURL: API_URL,
    });
  }, [API_URL, method, API_KEY, ACCESS_COOKIE]);

  const axiosInstanceNextAPI = useMemo(() => {
    if (method === AuthMethod.credentials) {
      return axios.create({
        baseURL: NEXT_API_URL,
        headers: {
          Authorization: `token ${ACCESS_COOKIE}`,
        },
      });
    }
    if (method === AuthMethod.apiKey) {
      return axios.create({
        baseURL: NEXT_API_URL,
        headers: {
          "x-api-key": API_KEY,
        },
      });
    }
    return axios.create({
      baseURL: NEXT_API_URL,
    });
  }, []);

  const API_ROUTES = useMemo(() => {
    return ApiRoutes;
  }, []);

  const API_ROUTES_COMPANY = useMemo(() => {
    return ApiRoutesCompany(COMPANY_ID as string);
  }, [COMPANY_ID]);

  const NEXT_API_ROUTES_COMPANY = useMemo(() => {
    return NextApiRoutes(COMPANY_ID as string);
  }, [COMPANY_ID]);

  const APP_ROUTES = useMemo(() => {
    return AppRoutes;
  }, []);

  const APP_COMPANY_ROUTES = useMemo(() => {
    return AppCompanyRoutes(COMPANY_ID as string);
  }, [COMPANY_ID]);

  const MENU_ROUTES = useMemo(() => {
    return AppMenuRoutes(COMPANY_ID as string, t);
  }, [COMPANY_ID, t]);

  return (
    <GlobalAppStateContext.Provider
      value={{
        axiosInstance,
        axiosInstanceNextAPI,
        API_ROUTES,
        API_ROUTES_COMPANY,
        NEXT_API_ROUTES_COMPANY,
        APP_ROUTES,
        APP_COMPANY_ROUTES,
        COMPANY_ID: COMPANY_ID as string | undefined,
        MENU_ROUTES,
      }}>
      {children}
    </GlobalAppStateContext.Provider>
  );
};

export function useGlobalAppState(): GlobalAppState {
  const context = useContext<any>(GlobalAppStateContext);

  if (!context) {
    throw Error(
      "useGlobalAppState must be used within a GlobalAppStateProvider"
    );
  }
  return context;
}
